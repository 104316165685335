import React from "react";

class CostWidget extends React.Component {
    render() {
        return (
            <div>
                I am a cost widget page
            </div>
        )
    }
}


export default CostWidget;