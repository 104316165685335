import React from "react";
import mondaySdk from "monday-sdk-js";
import AttentionBox from "monday-ui-react-core/dist/AttentionBox.js"

const loadingUsersState = "loadingData";
const loadingCostsState = "loadingCosts";
const editableState = "editable";
const savingState = "saving";

const monday = mondaySdk();

function renderLoadingUsers() {
    return(
        <div>
            <AttentionBox title="Loading Users" type="primary" text="Loading user data from Monday"/>
        </div>
    );
}

function renderLoadingCosts() {
    return(
        <div>
            <AttentionBox title="Loading Costs" type="primary" text="Loading cost data from Monday"/>
        </div>
    );
}

function parseUserResults(results) {
    //todo: error
    if (results == null || results.data == null || results.data.users == null || !Array.isArray(results.data.users) ) return;
    const nextState = Object.assign({}, this.state);
    const users = [];
    results.data.users.forEach(item => {
        users.push({
            id: item.id,
            name: item.name,
            image: item.photo_thumb_small,
        });
    });

    nextState.currentState = loadingCostsState;
    nextState.users = users;
    this.setState(nextState);
    monday.storage.instance.getItem('costData').then(parseCostData.bind(this));
}

function parseCostData(results) {
    //todo: error
    if (results == null || results.data == null || !results.data.success ) return;
    const nextState = Object.assign({}, this.state);
    const users = [];
    const validData = results.data.value != null;
    try {
        if (validData) results.data.value = JSON.parse(results.data.value);
    } catch(e) {

    }
    this.state.users.forEach(user => {
        if (validData) {
            const value = results.data.value[user.id];
            if (value != null) {
                users.push(Object.assign(user, {cost: value, originalCost: value}));
                return;
            }
        }
        users.push(Object.assign(user, {cost: 0, originalCost: 0}));
    });
    nextState.currentState = editableState;
    nextState.users = users;
    this.setState(nextState);
}

function renderEditor() {
    console.log("state");
    console.log(this.state);
    let disabled = this.state === savingState;
    const editors = this.state.users.map((user) => {
        return (
            <div key={user.id}>
                <img src={user.image} width="25" height="25" />
                <span>{user.name}</span>
                <span>$<input type="number" disabled={disabled} value={user.cost} onChange={updateCost.bind(this, user.id)}/></span>
            </div>);
    })

    if (!disabled) {
        disabled = this.state.users.reduce((previous, user) => {
            if (typeof previous === "boolean") {
                if (!previous) return false;
            } else {
                return user.cost === user.originalCost && previous.cost === previous.originalCost;
            }
            return user.cost === user.originalCost;
        });
    }

    return (
        <div>
            <div>{editors}</div>
            <button onClick={saveCosts.bind(this)} disabled={disabled}>Save</button>
            <div>{this.state.error}</div>
        </div>
    );
}

function updateCost(userId, event) {
    const intValue = parseInt(event.target.value, 10);
    let modified = false;
    const nextState = Object.assign({}, this.state);
    this.state.users.forEach(user => {
        if (user.id != userId) return;
        user.cost = intValue;
        modified = true;
    });
    if (modified) {
        this.setState(nextState);
    }
}

function saveCosts() {
    const costs = {};
    this.state.users.forEach(user => {
        costs[user.id] = user.cost;
    });

    const nextState = Object.assign({}, this.state);
    nextState.state = savingState;
    this.setState(nextState);
    monday.storage.instance.setItem('costData', JSON.stringify(costs)).then((result) => {
        const nextState = Object.assign({}, this.state);
        nextState.currentState = editableState;
        if (!result) nextState.error = "Saving error";
    });
}

class CostEntry extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentState: loadingUsersState
        }
    }

    componentDidMount() {
        // fetch all users
        monday.api(`query { users(kind: non_guests) { id, name, photo_thumb_small  } }`).then(parseUserResults.bind(this));
    }

    render() {
        switch(this.state.currentState) {
            case loadingUsersState:
                return renderLoadingUsers.call(this);
            case loadingCostsState:
                return renderLoadingCosts.call(this);
            case editableState:
            case savingState:
                return renderEditor.call(this);
        }

        return (
            <div>
                I am a cost entry page
            </div>
        )
    }
}


export default CostEntry;
