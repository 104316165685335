import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "monday-ui-react-core/dist/main.css"
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom'
import App from './App';
import { CostEntry, CostWidget } from './pages/index'
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
    <Router>
        <Routes>
            <Route exact path="/" element={<CostEntry/>} />
            <Route path="/CostEntry" element={<CostEntry/>} />
            <Route path="/CostWidget" element={<CostWidget/>} />
        </Routes>
    </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
